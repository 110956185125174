<template>
    <div>
        <div class="bank-select">
            <label for="emailField">{{$t('select-bank')}}</label>
            <v-select v-model="defaultOption" label="bankName" :options="bankOptions" @input="onChange($event)" >
                <template slot="option" slot-scope="option">
                  <img class="bank-logo" :src="getBankLogo(option.swiftCode)" />
                  <span class="bank-name">{{ option.bankName }}</span>
                </template>
            </v-select>
        </div>
        </div>
</template>

<script>
    import vSelect from 'vue-select'
    import {getBankOptions} from "../../services/backend.service";
    import {getBankLogo} from "../../helpers/helpers";

    export default {
        name: 'BankSelect',
        props:{
            requestPath: String,        
        },
        data(){
            return{
                bankOptions: [],
                defaultOption: null,
            }   
            
        },
        components:{
            vSelect,
        },
        mounted() {
            const _this = this;
            getBankOptions(this.requestPath).then(res => {
                if(res.data.success && res.data.data){
                    _this.bankOptions = res.data.data;
                    _this.defaultOption = _this.bankOptions[0];
                    _this.onChange(_this.defaultOption);
                }
            }).catch( e => {
                console.log(`%c Error in BankSelect.vue - method: getBankOptions - ${e}`, 'color:red');
            });
        },
        methods:{
            getBankLogo(swiftCode){return getBankLogo(swiftCode);},
            onChange(val) {
                this.$emit('selectedBank', val);
            }
        }
    }
</script>

<style scoped></style>
